<template>
  <div id="user-profile">

    <b-card
      class="profile-header mb-2"
      :img-src="require(`@/assets/images/profile/user-uploads/timeline.jpg`)"
      img-top
      alt="cover photo"
      body-class="p-0"
    >
      <!-- profile picture -->
      <div class="position-relative">
        <div class="profile-img-container d-flex align-items-center2">
          <div class="profile-img align-items-center text-center">
            <b-img
              :src="userData.avatar"
              rounded
              fluid
              alt="profile photo"
            />
          </div>

          <div class="profile-title ml-2">
            <h2 class="text-white">
              {{ userData.name }}
            </h2>
            <p class="text-white">
              {{ userData.email }}
            </p>
          </div>
        </div>
      </div>

      <b-row class="mt-2">

        <b-col>
          <b-card
            class="mb-0"
          >
            <b-tabs v-model="tabIndex" nav-class="mb-2">
              <b-tab>
                <template #title>
                  <feather-icon icon="UserIcon" />
                  <span>{{ $t('prof.account') }}</span>
                </template>
                <b-form
                  v-if="!userData.is_premium"
                  action="https://pay.topolingua.com/create-checkout-session"
                  method="POST"
                  target="_blank"
                  class="mb-2"
                >
                  <h3 class="mt-0 mb-1 mt-2">{{ $t('prof.upgradeYourAcc') }}
                  </h3>

                  <!-- Add a hidden field with the lookup_key of your Price -->
                  <!-- <input type="hidden" name="lookup_key" value="topolinguapayment" /> -->
                  <input type="hidden" name="client_reference_id" :value="userData.id">
                  <input type="hidden" name="customer_email" :value="userData.email">
                  <input type="hidden" name="name" :value="userData.name">
                  <input type="hidden" name="planselected" :value="planSelected">

                  <b-form-group
                    :label="$t('prof.selectPlan')"
                    label-for="selectplan"
                    style="display: inline-block;"
                    class="mr-1">
                    <b-form-select
                      id="selectplan"
                      v-model="planSelected"
                      :options="subscriptionsOptions"
                      class=""
                      style="margin-right: 10px;"
                    />
                  </b-form-group>

                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-success"
                    type="submit"
                    class="ml-0"
                  >{{ $t('prof.upgrade') }}
                  </b-button>
                </b-form>

                <h3 class="mt-2 mb-2">{{ $t('prof.accountDetail') }}</h3>
                <dl class="row">
                  <dt class="col-sm-3 pb-1">
                    <feather-icon icon="AwardIcon" /> {{ $t('prof.tariff') }}
                  </dt>
                  <dd class="col-sm-9">
                    <b>
                      <span
                        v-if="userData.is_premium"
                        class="text-success"
                      >{{ userData.subscription_name }}</span>
                      <span
                        v-else
                        class="text-danger"
                      >{{ $t('prof.limited') }}</span>
                    </b>

                    <b-form
                      v-if="userData.is_premium"
                      action="https://pay.topolingua.com/create-portal-session"
                      method="POST"
                      target="_blank"
                      class="d-inline"
                    >
                      <input type="hidden" name="stripe_customer_id" :value="userData.stripe_customer_id">
                      <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="outline-primary"
                        type="submit"
                        class="mt-0 ml-1"
                        size="sm"
                      >{{ $t('prof.change') }}
                      </b-button>
                    </b-form>

                  </dd>
                </dl>
                <dl class="row">
                  <dt class="col-sm-3 pb-1">
                    <feather-icon icon="MessageSquareIcon" /> {{ $t('prof.messages') }}
                  </dt>
                  <dd class="col-sm-9">
                    <b>
                      <span>{{ userData.msg_count }}</span>
                      <span> / {{ userData.msg_limit }}</span>
                    </b>
                  </dd>
                </dl>
                <dl class="row" v-if="userData.is_premium">
                  <dt class="col-sm-3 pb-1">
                    <feather-icon icon="CalendarIcon" /> {{ $t('prof.subscriptionEnding') }}
                  </dt>
                  <dd class="col-sm-9">
                    <b>{{ userData.subscription_period_end | formatDate }}</b>
                  </dd>
                </dl>
                <dl class="row">
                  <dt class="col-sm-12">
                    <h3 class="mt-0 mb-2">{{ $t('prof.accountManagement') }}</h3>
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="outline-secondary"
                      class=""
                      href="/forgot-password"
                      target="_blank"
                    >{{ $t('prof.resetPassword') }}
                    </b-button>
                  </dt>
                </dl>

              </b-tab>

              <b-tab>

                <template #title>
                  <feather-icon icon="GlobeIcon" />
                  <span>{{ $t('prof.languages') }}</span>
                </template>
                <b-overlay
                  :show="!isLanguagesLoaded"
                  rounded="sm"
                >
                  <b-row>
                    <b-col
                      md="4"
                      class="mb-0"
                    >
                      <h3 class="card-title nlang-title">{{ $t('prof.nativeLanguage') }}
                      </h3>
                      <b-form
                        ref="formnativelanguage"
                        class="nativelang"
                      >
                        <b-form-select
                          id="native-languages"
                          v-model="nativeLanguage"
                          :options="optionsLangs.languages"
                          :state="nativeLanguage === null ? false : null"
                        />
                      </b-form>
                    </b-col>
                    <b-col
                      md="4"
                      class="mb-0"
                    >
                      <h3 class="card-title nlang-title">{{ $t('prof.interface') }}
                      </h3>
                      <b-form
                        ref="forminterfacelanguage"
                        class="nativelang"
                      >
                        <b-form-select
                          id="interface-languages"
                          v-model="interfaceLanguage"
                          :options="optionsLangs.languages"
                        />
                      </b-form>
                    </b-col>
                  </b-row>

                  
                  <h3 class="card-title mb-1">
                    {{ $t('prof.targetLanguages') }}
                  </h3>
                  <p class="mb-2"><feather-icon icon="AlertCircleIcon" />
                    {{ $t('prof.targetLangDesc') }}
                  </p>
                  <div>
                    <div>
                      <b-form
                        ref="formlanguages"
                        :style="{height: trHeight}"
                        class="repeater-form"
                      >

                        <b-row
                          v-for="(item, index) in itemsTargetLanguages"
                          :id="item.id"
                          :key="item.id"
                          ref="rowlanguages"
                        >

                          <b-col md="3">
                            <b-form-group
                              :label="$t('prof.language')"
                              label-for="languages"
                            >
                              <b-form-select
                                id="languages"
                                v-model="item.selectedLanguage"
                                :options="optionsLangs.languages"
                                :state="item.selectedLanguage === null ? false : null"
                              />
                            </b-form-group>
                          </b-col>

                          <b-col md="2">
                            <b-form-group
                              :label="$t('prof.level')"
                              label-for="levels"
                            >
                              <b-form-select
                                id="levels"
                                v-model="item.selectedLevel"
                                :options="optionsLangs.levels"
                                :state="item.selectedLevel === null ? false : null"
                              />
                            </b-form-group>
                          </b-col>

                          <b-col md="2">
                            <b-form-group
                              :label="$t('prof.voice')"
                              label-for="voices"
                            >
                              <b-form-select
                                id="voices"
                                v-model="item.selectedVoice"
                                :options="optionsLangs.voices"
                                :state="item.selectedVoice === null ? false : null"
                              />
                            </b-form-group>
                          </b-col>

                          <b-col
                            lg="2"
                            md="3"
                            class="mb-50"
                          >
                            <b-button
                              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                              variant="outline-danger"
                              class="mt-0 mt-md-2"
                              @click="removeItem(index)"
                            >
                              <feather-icon
                                icon="XIcon"
                                class="mr-25"
                              />
                              <span>{{ $t('prof.delete') }}</span>
                            </b-button>
                          </b-col>
                          <b-col cols="12">
                            <div
                              v-if="getCharactersByLang(item.selectedLanguage)"
                              class="mt-1 mt-md-0"
                            >
                              <small>{{ $t('prof.characters') }}</small>
                              <b-badge
                                v-for="(char, ind) in getCharactersByLang(item.selectedLanguage)"
                                :key="index + ind"
                                variant="light-primary"
                                class="d-inline ml-1 badge-glow"
                              >
                                <b-avatar
                                  size="36"
                                  class="badge-minimal avatar-border-2 char-avatar"
                                  :src="char.avatar"
                                  variant="primary"
                                /> {{ char.character }}
                              </b-badge>
                            </div>
                            <div
                              v-else
                            >
                              <small>{{ $t('prof.noCharacters') }}</small>
                            </div>
                            <hr>
                          </b-col>
                        </b-row>

                      </b-form>

                    </div>
                    <div class="add-lang-butn">
                      <b-row>
                        <b-col
                          md="10"
                          class="mb-2"
                        >
                          <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="outline-success"
                            @click="repeateAgain"
                          >
                            <feather-icon
                              icon="PlusIcon"
                              class="mr-25"
                            />
                            <span>{{ $t('prof.addLanguage') }}</span>
                          </b-button>
                        </b-col>
                        <b-col cols="12">
                          <hr>
                        </b-col>
                      </b-row>
                    </div>
                    <div class="save-lang-btn">
                      <b-row>
                        <b-col
                          md="12"
                          class="mb-50 mt-2"
                        >
                          <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="primary"
                            size="lg"
                            @click="saveLanguages"
                          >
                            <feather-icon
                              icon="SaveIcon"
                              class="mr-25"
                              size="22"
                            />
                            <span>{{ $t('prof.saveLanguages') }}</span>
                          </b-button>
                        </b-col>
                      </b-row>
                    </div>
                  </div>
                </b-overlay>
              </b-tab>
              <b-tab>
                <template #title>
                  <feather-icon icon="DollarSignIcon" />
                  <span>{{ $t('prof.billing') }}</span>
                </template>
                <h3 class="mt-1 mb-2">{{ $t('prof.billingHistory') }}</h3>
                <p v-if="totalRows === 0 && isBillingLoaded">{{ $t('prof.noBillingHistory') }}</p>
                <p v-if="!isBillingLoaded">
                  <b-spinner
                    class="mr-1"
                    variant="info"
                  />
                  {{ $t('loading') }}
                </p>
                <app-timeline>
                  <app-timeline-item
                    v-for="item in items"
                    :key="item.id"
                    :variant="colorByTrType(item.ev_type)"
                    :icon="iconByTrType(item.ev_type)"
                  >
                    <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0" >
                      <h6><b>{{ item.ev_type_name }}</b><span v-if="item.description"><i> ({{ item.description }})</i></span></h6>
                      <small class="text-muted">{{ item.ev_created | formatDate }}</small>
                    </div>
                    <div class="d-flex flex-sm-row flex-column justify-content-between align-items-start">

                      <div class="mb-1 mb-sm-0">
                        <span class="text-muted mb-50 d-block">{{ $t('prof.paymentSystem') }}</span>
                        <span>{{ item.payment_system_name }}</span>
                      </div>

                      <div class="mb-1 mb-sm-0" v-if="item.ev_type === 1">
                        <span class="text-muted mb-50 d-block">{{ $t('prof.period') }}</span>
                        <span><b>{{ item.ev_period_start | formatDate }} - {{ item.ev_period_end | formatDate }}</b></span>
                      </div>

                      <div v-if="item.ev_type === 2 || item.ev_type === 4">
                        <span class="text-muted mb-50 d-block">{{ $t('prof.invoice') }}</span>
                        <b-button
                          v-b-toggle.report-list-with-icon
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          size="sm"
                          :variant="item.ev_type === 2 ? 'outline-success' : 'outline-info'"
                          :href="item.ev_invoice_url"
                          target="_blank"
                          class="mr-1 mb-1"
                        >
                          <feather-icon
                            icon="LinkIcon"
                            class="mr-50"
                          />{{ $t('prof.view') }}
                        </b-button>
                        <b-button
                          v-b-toggle.report-list-with-icon
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          size="sm"
                          :variant="item.ev_type === 2 ? 'outline-success' : 'outline-info'"
                          :href="item.ev_invoice_pdf"
                          target="_blank"
                          class="mb-1"
                        >
                          <feather-icon
                            icon="ArrowDownCircleIcon"
                            class="mr-50"
                          />{{ $t('prof.download') }}
                        </b-button>
                      </div>

                      <div>
                        <span v-if="item.ev_type === 2 || item.ev_type === 4" class="text-muted mb-50 d-block">{{ $t('prof.amount') }}</span>
                        <span v-else class="text-transparent mb-50 d-block">{{ $t('prof.amount') }}</span>
                        <span v-if="item.ev_type === 2 || item.ev_type === 4"><b>€ {{ item.ev_amount_total | formatCentsToEuro }}</b></span>
                      </div>

                    </div>
                  </app-timeline-item>
                </app-timeline>
                <b-card>
                  <b-button
                    v-if="!itemsOpenedAll && totalRows > 0"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    @click="loadAllTransactions"
                  >
                    <span class="align-middle">{{ $t('prof.viewAll') }}</span>
                    <feather-icon
                      icon="ArrowDownIcon"
                      class="ml-50"
                    />
                  </b-button>
                </b-card>
              </b-tab>
            </b-tabs>
          </b-card>
        </b-col>
      </b-row>

    </b-card>

    <section id="card-navigation">
      <b-card
        v-if="userData.subscription_period_end"
        class="mt-2"
      >
        <b-card-title>
          <feather-icon icon="AwardIcon" />
          {{ $t('prof.subscriptionManagement') }}
        </b-card-title>
        <b-card-text>
          <p>{{ $t('prof.youCanManage') }}</p>
          <p v-html="$t('prof.manageSubscriptionText')"/>

          <b-form action="https://pay.topolingua.com/create-portal-session" method="POST" target="_blank">
            <input type="hidden" name="stripe_customer_id" :value="userData.stripe_customer_id">
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              type="submit"
              class="mt-1"
            >
              <feather-icon icon="AwardIcon" class="mr-50" />
              {{ $t('prof.manageYourSubscription') }}
            </b-button>
          </b-form>

        </b-card-text>
      </b-card>
    </section>

    <b-modal
      ref="modal-form-success"
      centered
      :title="$t('prof.paymentSuccess')"
      modal-class="modal-success"
      ok-only
      ok-title="Ok"
      ok-variant="success"
      @hide="clearUrlParam"
    >
      <div class="d-block text-center">
        <h3>{{ $t('prof.thankYouForSubscribe') }}</h3>
      </div>

    </b-modal>

  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BTab, BTabs, BCardText, BCardTitle, BButton, BForm, BFormGroup, BImg, BModal, VBToggle, BSpinner, BFormSelect, BAvatar, BBadge,
  BNavbar, BNavbarToggle, BCollapse, BNavItem, BOverlay,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-cycle
import useJwt from '@/auth/jwt/useJwt'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import NavBar from '@/layouts/components/Navbar.vue'
import Ripple from 'vue-ripple-directive'
// import store from '@/store'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import { heightTransition } from '@core/mixins/ui/transition'
import { clearUserDatePartial } from '@/auth/utils'

export default {
  components: {
    // eslint-disable-next-line object-property-newline
    BRow, BCol, BCard, BTab, BTabs, BCardText, BCardTitle, BButton, BForm, BFormGroup, BImg, BModal, AppTimeline, AppTimelineItem, BSpinner, BFormSelect, BAvatar, BBadge,
    // eslint-disable-next-line vue/no-unused-components, object-property-newline
    BNavbar, BNavbarToggle, BCollapse, BNavItem, BOverlay,
    // , NavBar
    // , store
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  mixins: [heightTransition],
  data() {
    return {
      freeAccountLimit: 10,
      userData: JSON.parse(localStorage.getItem('userData')),
      items: [],
      itemsOpenedAll: false,
      totalRows: 0,
      tabIndex: 0,
      paymentShow: false,
      isBillingLoaded: false,
      isLanguagesLoaded: false,
      planSelected: '0',
      subscriptionsOptions: [],
      // itemsTargetLanguages: [{
      //   id: 1,
      //   selectedLanguage: null,
      //   selectedLevel: null,
      //   selectedVoice: null,
      // }],

      itemsTargetLanguages: [],
      nextLanguagesId: 1,
      nativeLanguage: null,
      interfaceLanguage: null,

      optionsLangs: {
        // languages: [
        //   { value: null, text: 'Select language' },
        //   { value: '1', text: 'English' },
        //   { value: '2', text: 'Ukrainian' },
        //   { value: '3', text: 'China', disabled: true },
        // ],
        // levels: [
        //   { value: null, text: 'Select level' },
        //   { value: '1', text: 'Beginner' },
        //   { value: '2', text: 'Intermediate' },
        // ],
        // voices: [
        //   { value: null, text: 'Select voice' },
        //   { value: '1', text: 'Male' },
        //   { value: '2', text: 'Female' },
        // ],
        languages: [],
        levels: [],
        voices: [],
      },

    }
  },

  watch: {
    tabIndex() {
      if (this.tabIndex === 1) {
        this.getUserLanguages()
      }
      // console.log(this.tabIndex)
    },
  },

  mounted() {
    this.getUserProfile()
    // this.initTrHeight()

    // uncomment after back ready
    this.loadTopTransactions()

    if (this.$route.query.status === 'success') {
      this.showPaymentSuccess()
    }
    // uncomment after back ready
    this.loadSubscriptions()
  },

  created() {
    window.addEventListener('resize', this.initTrHeight)
    // for url like ...#tab=1
    if (this.$route.query.tab) {
      this.tabIndex = parseInt(this.$route.query.tab, 10)
    }
    // for push like this.$router.push({ name: 'pages-profile', params: { tabIndex: 1 } })
    if (this.$route.params.tab) {
      this.tabIndex = this.$route.params.tab
    }

    if (this.$route.params.msgAboutEmptyLanguages) {
      this.$swal({
        title: this.$t('prof.plsSetLanguages'),
        // text: 'To operate with assistants you need to select native language and targer learning languages!',
        html: this.$t('prof.selectLanguageMsg'),
        icon: 'info',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
        allowOutsideClick: false,
      })
    }
  },

  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    logout() {
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)
      // localStorage.removeItem('userData')
      clearUserDatePartial()
      this.$router.push({ name: 'auth-login', params: { needToRefreshPage: true } })
    },
    getUserProfile() {
      useJwt.getUserProfile({
        // src_text: this.textQuiz,
      })
        .then(response => {
          const resData = response.data
          // console.log("response.data:")
          // console.log(response.data)
          // this.items = resData
          // Set the initial number of items

          this.userData.id = resData[0].id
          this.userData.name = resData[0].name
          this.userData.credits = resData[0].credits
          this.userData.email = resData[0].email
          this.userData.is_premium = resData[0].is_premium
          this.userData.subscription_period_end = resData[0].subscription_period_end
          this.userData.stripe_customer_id = resData[0].stripe_customer_id
          this.userData.msg_count = resData[0].msg_count
          this.userData.msg_limit = resData[0].msg_limit

          // this.userData.symbols_free_alg = resData[0].symbols_free_alg
          // this.userData.symbols_paid_alg = resData[0].symbols_paid_alg
          // this.userData.char_limit_alg_free = resData[0].char_limit_alg_free
          // this.userData.char_limit_alg_paid = resData[0].char_limit_alg_paid
          this.userData.subscription_name = resData[0].subscription_name
          this.userData.subscription_code = resData[0].subscription_code
          this.userData.avatar = resData[0].avatar
          this.userData.native_language = resData[0].native_language
          this.userData.native_language_name = resData[0].native_language_name
          this.userData.interface_language = resData[0].interface_language
          this.$i18n.locale = this.userData.interface_language.lang_code

          localStorage.setItem('userData', JSON.stringify(this.userData))
          // document.getElementById('nb-username').innerHTML =this.userData.name;
          this.updateNavbarProfile()
          // NavBar.userData = this.userData;
          // window.location.reload(true);
          // NavBar.forceReload += 1;
          // store.state.appConfig.globalUserData = userData;
          // console.log(this.$store.appConfig.globalUserData);
          // console.log(store.state.appConfig.isRTL)
        })
        .catch(error => {
          // this.$refs.loginForm.setErrors(error.data);
          // console.log(error)
          if (error.status === 401) {
            this.logout()
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: this.$t('toast.errGetUserProfile'),
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: error.data.email ? error.data.email : '',
              },
            })
          }
        })
        .finally(() => {
          // this.seenTable = true
        })
    },

    getUserLanguages() {
      this.isLanguagesLoaded = false
      useJwt.getUserLanguages({
      })
        .then(response => {
          const resData = response.data
          this.optionsLangs.languages = resData.languages
          this.optionsLangs.levels = resData.levels
          this.optionsLangs.voices = resData.voices
          this.optionsLangs.characters = resData.characters
          this.itemsTargetLanguages = resData.target_languages
          this.nativeLanguage = resData.native_language
          this.interfaceLanguage = resData.interface_language
          this.nextLanguagesId = resData.target_languages.length
        })
        .catch(error => {
          if (error.status === 401) {
            this.logout()
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: this.$t('toast.errGetUserLanguages'),
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: error.data.email,
              },
            })
          }
        })
        .finally(() => {
          this.initTrHeight()
          this.isLanguagesLoaded = true
        })
    },

    loadTopTransactions() {
      this.isBillingLoaded = false
      useJwt.getTopTransactions({
        // src_text: this.textQuiz,
      })
        .then(response => {
          const resData = response.data
          this.items = resData
          this.totalRows = this.items.length
          // console.log(this.items);
          // this.totalRows = this.items.length
        })
        .catch(error => {
          if (error.status === 401) {
            this.logout()
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: this.$t('toast.errGetTransactions'),
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: error.data.email,
              },
            })
          }
        })
        .finally(() => {
          // this.seenTable = true
          this.isBillingLoaded = true
        })
    },

    loadAllTransactions() {
      this.isBillingLoaded = false
      useJwt.getAllTransactions({
        // src_text: this.textQuiz,
      })
        .then(response => {
          const resData = response.data
          this.items = resData
          this.totalRows = this.items.length
          this.itemsOpenedAll = true
          // console.log(this.items);
          // this.totalRows = this.items.length
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('toast.errGetTransactions'),
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error.data.email,
            },
          })
        })
        .finally(() => {
          // this.seenTable = true
          this.isBillingLoaded = true
        })
    },

    loadSubscriptions() {
      useJwt.getSubscriptions({
        // src_text: this.textQuiz,
      })
        .then(response => {
          this.subscriptions = response.data
          // eslint-disable-next-line no-restricted-syntax, guard-for-in
          for (const index in this.subscriptions) {
            // eslint-disable-next-line prefer-template
            this.subscriptionsOptions.push({ value: this.subscriptions[index].id, text: this.subscriptions[index].name + ' (€' + this.subscriptions[index].price + ')' })
          }

          if (this.$route.params.planIndex) {
            this.planSelected = this.subscriptions[this.$route.params.planIndex - 1].id
          } else
            // this.planSelected = this.subscriptions[this.subscriptions.length-1].id;
            this.planSelected = 2;
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('toast.errGetSubscriptions'),
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              // text: error.data.email,
              text: error.data.email,
            },
          })
        })
        .finally(() => {
          //
        })
    },

    iconByTrType(evType) {
      switch (evType) {
        case 0:
          return 'UserCheckIcon'
        case 1:
          return 'AwardIcon'
        case 2:
          return 'DollarSignIcon'
        case 4:
          return 'ShuffleIcon'
        default:
          return 'ClipboardIcon'
      }
    },
    colorByTrType(evType) {
      switch (evType) {
        case 0:
          return 'warning'
        case 1:
          return 'primary'
        case 2:
          return 'success'
        case 4:
          return 'info'
        default:
          return 'primary'
      }
    },

    updateNavbarProfile() {
      document.getElementById('nb-username').innerHTML = this.userData.name
      var ps =  document.getElementById('profile-state').getElementsByClassName('b-avatar-badge')[0];
      if (this.userData.is_premium) {
        ps.classList.remove('badge-secondary')
        ps.classList.add('badge-success')
      } else {
        ps.classList.remove('badge-success')
        ps.classList.add('badge-secondary')
      }

      document.getElementById('header-il-original').innerHTML = this.userData.interface_language.original_name
      document.getElementById('header-il-icon').src = this.userData.interface_language.icon
    },

    showPaymentSuccess() {
      this.$refs['modal-form-success'].show()
    },

    clearUrlParam() {
      this.$router.push({ name: 'pages-profile' })
    },

    repeateAgain() {
      // alert(this.nextLanguagesId)
      // console.log(JSON.stringify(this.itemsTargetLanguages))
      this.nextLanguagesId += 1
      this.itemsTargetLanguages.push({
        id: this.nextLanguagesId,
        selectedLanguage: null,
        selectedLevel: null,
        selectedVoice: null,
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.rowlanguages[0].offsetHeight)
      })
    },
    removeItem(index) {
      this.itemsTargetLanguages.splice(index, 1)
      this.trTrimHeight(this.$refs.rowlanguages[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.formlanguages.scrollHeight)
      })
    },

    hasDuplicates(a) {
      const noDups = new Set(a)
      return a.length !== noDups.size
    },

    saveUserLanguages() {
      useJwt.saveUserLanguages({
        itemsTargetLanguages: this.itemsTargetLanguages,
        nativeLanguage: this.nativeLanguage,
        interfaceLanguage: this.interfaceLanguage,
      })
        .then(response => {
          // const resData = response.data
          this.getUserProfile()
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('toast.success'),
              icon: 'AlertTriangleIcon',
              variant: 'success',
              text: this.$t('toast.langSaved'),
            },
          })
          if (this.$route.params.returnTo) {
            this.$router.push({ name: this.$route.params.returnTo, params: { } })
          }
        })
        .catch(error => {
          if (error.status === 401) {
            this.logout()
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: this.$t('toast.errSaveTargetLanguages'),
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: error.data.email ? error.data.email : '',
              },
            })
          }
        })
        .finally(() => {
        })
    },

    saveLanguages() {
      // console.log(JSON.stringify(this.itemsTargetLanguages))

      // check for empty native language
      if (!this.nativeLanguage) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: this.$t('toast.errCheckNativeLang'),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: this.$t('toast.plsSetNativeLang'),
          },
        })
        return
      }

      const langArr = []
      // check for empty
      if (this.itemsTargetLanguages.length === 0) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: this.$t('toast.errCheckTargetLanguages'),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: this.$t('toast.plsSetAllDataTargetLanguages'),
          },
        })
        return
      }
      // check for nulls
      for (let i = 0; i < this.itemsTargetLanguages.length; i += 1) {
        langArr.push(this.itemsTargetLanguages[i].selectedLanguage)
        if (!this.itemsTargetLanguages[i].selectedLanguage || !this.itemsTargetLanguages[i].selectedLanguage || !this.itemsTargetLanguages[i].selectedVoice) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('toast.errCheckTargetLanguages'),
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: this.$t('toast.plsSetAllDataTargetLanguages'),
            },
          })
          return
        }
      }
      // check for duplicate
      if (this.hasDuplicates(langArr)) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: this.$t('toast.errCheckTargetLanguages'),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: this.$t('toast.targetListHasDiplicates'),
          },
        })
        return
      }
      // save
      this.saveUserLanguages()
    },

    getCharactersByLang(lang) {
      const filteredLanguages = this.optionsLangs.languages.filter(x => x.value === lang)
      if (filteredLanguages[0]) {
        if (filteredLanguages[0].characters.length) {
          return filteredLanguages[0].characters
        }
      }
      return null
    },

  },

}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/pages/page-profile.scss';
  .repeater-form {
    overflow: hidden;
    transition: .35s height;
  }

  .nativelang {
    margin-bottom: 40px;
  }

  .nlang-title {
    // margin-top: 40px;
    margin-bottom: 10px;
  }



  .char-avatar.avatar-border-2 img {
    border: 1px solid #bcb6f8;
  }

  #user-profile .profile-header .profile-img-container .profile-img {
    height: auto;
    width: 106px;
  }

  @media all and (min-width : 992px) {
    .card-img-top {
      height: 250px;
    }
  }
  @media all and (min-width : 1922px) {
    .card-img-top {
      height: 350px;
    }
  }

</style>

<style lang="scss">


</style>
